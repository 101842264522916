import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { addDays, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().toISOString();
      const timeZone = "America/Los_Angeles";
      const zonedTime = utcToZonedTime(now, timeZone);
      const tomorrow = startOfDay(addDays(zonedTime, 1));

      const diff = tomorrow - zonedTime;

      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((diff / (1000 * 60)) % 60)
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((diff / 1000) % 60)
        .toString()
        .padStart(2, "0");

      setTimeLeft(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Typography
      variant="body1"
      sx={{
        position: "absolute",
        left: "0%",
        top: "50%",
        transform: "translateY(-50%)",
        fontFamily: "Arial",
      }}>
      Next game in <br></br>
      {timeLeft}
    </Typography>
  );
};

export default CountdownTimer;
