import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Typography,
  ThemeProvider,
  Container,
} from "@mui/material";
import Papa from "papaparse";
import { format, utcToZonedTime } from "date-fns-tz";
import "../styles/fonts.css";
import theme from "../styles/theme";
import { NavigationBar, AnnouncementBar } from "../components/NavigationBar.js";
import CountdownTimer from "../components/CountdownTimer.js";
import RandomPageButton from "../components/RandomPageButton.js";
import SearchBar from "../components/SearchBar.js";
import MultipleAnswersGuessBar from "../components/MultipleAnswersGuessBar.js";
import Footer from "../components/Footer.js";
import EuroCupEntry from "../datamodels/EuroCupEntry.js";
import EuroCupTable from "../components/EuroCupTable.js";

const EUROCUP_FILE_NAMES = {
  // "com_europameisterschaft-2024_teilnehmer_pokalwettbewerb_EM24_saison_id_2023.csv",
  // "com_euro-2024_abstellungwettbewerb_pokalwettbewerb_EM24_plus_1saison_id_2023.csv",
  // "com_euro-2024_abstellungverein_pokalwettbewerb_EM24_plus_1saison_id_2023.csv",
  "com_euro-2024_scorerliste_pokalwettbewerb_EM24_saison_id_2023_altersklasse.csv":
    "Guess the top scorers for Euro 2024",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_2023.csv":
    "Guess the top goal scorers for Euro 2024",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_2020.csv":
    "Guess the top goal scorers for Euro 2021",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_2015.csv":
    "Guess the top goal scorers for Euro 2016",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_2011.csv":
    "Guess the top goal scorers for Euro 2012",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_2007.csv":
    "Guess the top goal scorers for Euro 2008",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_2003.csv":
    "Guess the top goal scorers for Euro 2004",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_1999.csv":
    "Guess the top goal scorers for Euro 2000",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_1995.csv":
    "Guess the top goal scorers for Euro 1996",
  "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_1991.csv":
    "Guess the top goal scorers for Euro 1992",
  // "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_1987.csv":
  //   "Guess the top goal scorers for Euro 1988",
  // "com_euro-2024_torschuetzenliste_pokalwettbewerb_EM24_plus_1_galerie_0saison_id_1983.csv":
  //   "Guess the top goal scorers for Euro 1984",
  "com_euro-2024_weisseweste_pokalwettbewerb_EM24_saison_id_2023_plus_1.csv":
    "Guess which goalkeeper has the most clean sheets for Euro 2024",
  "com_europameisterschaft-2024_nichtberuecksichtigt_pokalwettbewerb_EM24.csv":
    "Guess the top players who have been leaft out of their squads for Euro 2024",
  "selectedSpielerposition_id_0_plus_1_juengste.csv":
    "Guess the youngest player who played during all past Euro Cups",
};

export const EuroCup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allTriviaData, setAllTriviaData] = useState([]);
  const [shuffledTriviaData, setShuffledTriviaData] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [guessedPlayer, setGuessedPlayer] = useState([]);
  const [revealedPlayers, setRevealedPlayers] = useState([]);
  const [guessState, setGuessState] = useState("");
  const [guessPoints, setGuessPoints] = useState(0); // Initial guess points
  const [dataShuffled, setDataShuffled] = useState(false);
  const [triviaQuestionPrompt, setTriviaQuestionPrompt] = useState(
    "All trivia about Euro Cup!"
  );
  const location = useLocation();
  const showRandomGame = location.pathname.endsWith("random");

  // Function to handle player selection from SearchBar
  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  };

  // Function to handle the submission of the guess
  const handleSubmit = () => {
    // console.log(selectedPlayer.player.playerId);
    // console.log(guessedPlayer);
    if (selectedPlayer) {
      if (
        guessedPlayer.some(
          (player) => player.player.playerId === selectedPlayer.player.playerId
        )
      ) {
        setGuessState("PlayerAlreadyGuessed");
        console.log("Already guessed");
      } else {
        const isCorrectGuess = allTriviaData
          .slice(0, 10)
          .some((player) => player.playerId === selectedPlayer.player.playerId);
        console.log("Updating score:", guessPoints);
        if (isCorrectGuess) {
          setGuessState("PlayerRevealed");
          setGuessPoints((prevPoints) => prevPoints + 200);
          console.log("guess correct");
          setRevealedPlayers([...revealedPlayers, selectedPlayer]);
        } else {
          setGuessPoints((prevPoints) => prevPoints - 20); // Increase the points by 20
          setGuessState("WrongGuess");
        }

        setGuessedPlayer([...guessedPlayer, selectedPlayer]);
      }
    } else {
      setGuessState("NoPlayerSelected");
    }
    // Clear the selection from search bar
    setSelectedPlayer(null);
  };

  const onReveal = () => {
    setGuessPoints((prevPoints) => prevPoints - 50);
  };

  const seedrandom = require("seedrandom");
  // Function to get today's date in PDT as a string
  function getTodaysDateInSF() {
    // San Francisco, California timezone
    const timezone = "America/Los_Angeles";
    // Get the current date/time in the specific timezone
    const nowInSF = utcToZonedTime(new Date().toISOString(), timezone);
    // Format as 'YYYY-MM-DD'
    return format(nowInSF, "yyyy-MM-dd", { timeZone: timezone });
  }

  const findTriviaDataFileName = () => {
    // Use today's date in PDT as the seed
    const seed = getTodaysDateInSF();
    const rng = seedrandom(seed);
    const file_names = Object.keys(EUROCUP_FILE_NAMES);
    let todayIndex = Math.floor(rng() * file_names.length);
    if (showRandomGame) {
      todayIndex = Math.floor(Math.random() * (file_names.length));
    }
    const triviaFileName = file_names[todayIndex];
    console.log("Found the trivia file with seed " + seed);
    // console.log(triviaFileName);
    if (triviaFileName === undefined || triviaFileName === null)
      throw new Error("Invalid Trivia File Name");
    setTriviaQuestionPrompt(EUROCUP_FILE_NAMES[triviaFileName]);
    return triviaFileName;
  };

  const readCsv = async (url) => {
    const res = await fetch(url);
    const csv = await res.text();
    return await new Promise((resolve, reject) => {
      Papa.parse(csv, {
        header: true,
        complete: function (results) {
          resolve(results.data); // Resolve the promise with the parsed data
        },
        error: (error) => {
          console.error("Error while parsing CSV file:", error);
          reject(error); // Reject the promise on error
        },
      });
    });
  };

  useEffect(() => {
    const fetchTriviaData = async () => {
      try {
        const triviaFileName = findTriviaDataFileName();
        let csvData = await readCsv("/tables/" + triviaFileName);
        // Convert your players array to an options array for react-select
        const allTriviaData = csvData
          .filter((row) => row["Player Name"]) // Only converting players
          .map((player) => EuroCupEntry.createFromObject(player));
        setAllTriviaData(allTriviaData);
        setIsLoading(false); // Set loading to false after data is loaded
      } catch (error) {
        console.error("Error fetching CSV data:", error);
        setIsLoading(false);
      }
    };

    fetchTriviaData();
  }, []);

  // Logging the updated state
  useEffect(() => {
    if (allTriviaData.length > 0) {
      console.log("Parsing complete for trivia data");
      // console.log(allTriviaData);
      if (!dataShuffled) {
        // Shuffle the trivia data if it exists
        const _ = require("lodash");
        const seed = getTodaysDateInSF();
        const rng = seedrandom(seed); // Create a seeded random number generator
        const shuffledData = _.shuffle(allTriviaData, rng); // Use lodash shuffle with the seeded RNG
        setShuffledTriviaData(shuffledData);
        setDataShuffled(true);
      }
    } else {
      console.warn("No data was read for trivia");
    }
  }, [allTriviaData, dataShuffled, seedrandom]);

  // Update guess state
  useEffect(() => {
    if (revealedPlayers.length === 10) {
      setGuessState("CorrectGuess");
    }
    // console.log(revealedPlayers);
  }, [revealedPlayers]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="100%" disableGutters="true">
        <NavigationBar />
        <AnnouncementBar />
        <Container maxWidth="xl" sx={{ minHeight: "100vh" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              alignItems: "center",
            }}>
            <Typography
              variant="h1"
              sx={{
                padding: 2,
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "50px",
              }}>
              Euro Cup Trivia
            </Typography>
            <CountdownTimer />
            <RandomPageButton />
          </Box>
          <Typography
            variant="h2"
            sx={{
              padding: 2,
              textAlign: "center",
              fontSize: "25px",
            }}>
            {triviaQuestionPrompt}
          </Typography>
          {/* Additional components */}
          <Box>
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <SearchBar
                  id="searchInput"
                  players={shuffledTriviaData}
                  selectedPlayer={selectedPlayer}
                  onPlayerSelect={handlePlayerSelect}
                />
                <MultipleAnswersGuessBar
                  guessPts={guessPoints}
                  guessState={guessState}
                  handleSubmit={handleSubmit}
                />
              </Box>
            )}

            {!isLoading && (
              <EuroCupTable
                allTriviaData={allTriviaData}
                onReveal={onReveal}
                revealedPlayers={revealedPlayers}
              />
            )}
          </Box>
        </Container>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default EuroCup;
